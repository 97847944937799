const en_US = {
  platform: 'Intelligent AI system',
  Log_In: 'Log in',
  Log_up: 'Sign up',
  Forget_Password: 'Forgot Password?',
  // ForgotPassword: 'Send Reset Link',
  ReturnLogin: 'Return to Login',
  Sign_up: 'Sign up',
  Home: 'Home',
  InterviewLibrary: 'Interview Library',
  StudyLibrary: 'Study Library',
  PersonasLibrary: 'Personas Library',
  Report: 'Report',
  User: 'User',
  Search: ' Search',
  Welcome: 'Welcome,',
  Confirm: 'Confirm',
}
export default en_US
