const zh_CN = {
  platform: '佳沛电商数据分析平台',
  Log_In: '登录',
  Log_up: '注册',
  Forget_Password: '忘记密码',
  // ForgotPassword: '重置',
  ReturnLogin: '返回登录',
  Sign_up: '注册',
  Home: '首页',
  InterviewLibrary: '访谈',
  StudyLibrary: '研究',
  PersonasLibrary: 'PersonasLibrary',
  Report: 'Report',
  User: '产品概念管理',
  Search: '查找',
  Welcome: '欢迎,',
  Confirm: '确认',
}
export default zh_CN
